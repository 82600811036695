import store from "@/store";
import http from "@/axios/httpauth";
import msg from "@/utils/constants/msg";

/**
 * Function used to find out if a registration
 * product is available for the current entity
 * and member
 * @param {string} dob - ('YYYY-MM-DD')
 * @param {string} gender - Enum['Male', 'Female']
 * @param {number} memberId - Int
 * @return {boolean}
 */
async function getProduct(dob, gender, memberId) {
  // Gender Check
  // STG Data Import can contain members that don't have a gender
  if (!gender) {
    store.commit("views/PUSH_NOTIFICATION", {
      msg: msg.error.missingGender,
      type: "warning",
    });
    return false;
  }

  let foundProduct = false;
  try {
    const { entityType, _id: entityId } = store.getters["registration/current"].entity;
    const pricingResponse = await http.post("/nrl/api/v1/portal/registration/pricing", {
      entityType,
      entityId,
      dob,
      gender,
      memberId,
    });
    if (
      pricingResponse.data &&
      pricingResponse.data.status &&
      pricingResponse.data.status === "success"
    ) {
      const { data } = pricingResponse.data;

      if (data.length !== 0) {
        /* --------- START - BACKUP CHECKER FOR SAME TYPE & ENTITY REGO ------------ */
        // Find completed registrations for the same person && entity
        const foundUserRegos = store.state.registration.completed.filter(
          (o) => o.id === memberId && o.entity._id === entityId
        );
        // Filter available products based on same member type
        // eslint-disable-next-line arrow-body-style

        const notSameTypeRegos = data.filter((o) => {
          return !foundUserRegos.find((r) => r.type === o.memberType);
        });
        if (notSameTypeRegos.length > 0) {
          foundProduct = true;
          store.commit("registration/SET_PRICELIST", notSameTypeRegos);
        } else {
          foundProduct = false;
        }
        /* --------- END - BACKUP CHECKER FOR SAME TYPE & ENTITY REGO ------------ */
      }
    } else {
      store.commit("views/PUSH_NOTIFICATION", {
        msg: msg.error.apiError,
        type: "warning",
      });
      window.scrollTo(0, 0);
    }
    return foundProduct;
  } catch (error) {
    store.commit("views/PUSH_NOTIFICATION", {
      msg:
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : msg.error.apiError,
      type: "warning",
    });
    window.scrollTo(0, 0);
    return foundProduct;
  }
}

/**
 * Function used to map a persons profile into
 * the form structure for updating and verifying:
 * form_structure = {
 *    id
 *    verification
 *    usePhoto
 *    optinNrl
 *    participantForm
 *    emergencyForm
 *    parentForm
 *    additionalForm
 * }
 * @param {} person
 * @return {} form_structure
 */
export function mapProfile(person) {
  const {
    id,
    verification,
    usePhoto,
    optinNrl,
    optinTfa,
    firstName,
    middleName,
    lastName,
    mobile,
    email,
    dob,
    gender,
    address,
    ethnicity,
    isPrivate,
    activeRegos,
    seasonPasses,
    meta: {
      avatar,
      height,
      weight,
      preferredName,
      parentOrGuardian1: parentForm,
      favStateTeam,
      favNationalTeam,
      favNationalWomensTeam,
      postalAddress: {
        addressLine1: postalAddressLine1,
        addressLine2: postalAddressLine2,
        suburb: postalSuburb,
        state: postalState,
        postcode: postalPostcode,
        country: postalCountry,
      } = {
        addressLine1: undefined,
        addressLine2: undefined,
        suburb: undefined,
        state: undefined,
        postcode: undefined,
        country: undefined,
      },
      medical: { hasMedical, hasAllergies, allowedTreatment, notes } = {
        hasMedical: undefined,
        hasAllergies: undefined,
        allowedTreatment: undefined,
        notes: undefined,
      },
      disability: { hasDisability, disabilityType } = {
        hasDisability: undefined,
        disabilityType: undefined,
      },
      cob: {
        member,
        mother,
        father,
        motherGrandMother,
        motherGrandFather,
        fatherGrandMother,
        fatherGrandFather,
      } = {
        member: "",
        mother: "",
        father: "",
        motherGrandMother: undefined,
        motherGrandFather: undefined,
        fatherGrandMother: undefined,
        fatherGrandFather: undefined,
      },
      wwc: { number, issuer, expiry, status } = {
        number: undefined,
        issuer: undefined,
        expiry: undefined,
        status: undefined,
      },
    } = {
      // below is possibly undefined
      height: undefined,
      weight: undefined,
      parentOrGuardian1: undefined,
      preferredName: "",
      notes: undefined,
    },
    emergency: emergencyForm,
  } = person;
  return {
    id, // also record the id - used to send put request to backend
    avatar,
    verification,
    usePhoto,
    optinNrl,
    optinTfa,
    activeRegos,
    seasonPasses,
    participantForm: {
      mobile,
      email,
      dob,
      firstName,
      middleName,
      lastName,
      preferredName,
      gender,
      ...address,
      postalAddressLine1,
      postalAddressLine2,
      postalSuburb,
      postalState,
      postalPostcode,
      postalCountry,
      isPrivate,
      height,
      weight,
    },
    emergencyForm,
    parentForm,
    additionalForm: {
      ethnicity,
      favNationalTeam,
      favNationalWomensTeam,
      favStateTeam,
      cobMember: member,
      cobMother: mother,
      cobFather: father,
      cobMotherGrandMother: motherGrandMother,
      cobMotherGrandFather: motherGrandFather,
      cobFatherGrandMother: fatherGrandMother,
      cobFatherGrandFather: fatherGrandFather,
      hasMedical,
      hasAllergies,
      hasDisability,
      disabilityType,
      allowedTreatment,
      notes,
      wwcNumber: number,
      wwcIssuer: issuer,
      wwcExpiry: expiry,
      wwcStatus: status,
    },
  };
}

export async function storeProfile(person) {
  const foundProduct = await getProduct(person.dob, person.gender, person.id);
  if (foundProduct) {
    // Store the profile into currentProfile
    const profile = mapProfile(person);
    // DEV ONLY
    // if (process.env.NODE_ENV === 'development') {
    //   console.log('The mapped result of profile is: ', profile);
    // }
    store.dispatch("registration/updateCurrent", { profile });
    return true;
  }
  return false;
}

export async function checkProfileRego(person, entity) {
  const foundProduct = await getProduct(person.dob, person.gender, person.id);
  if (foundProduct) {
    const check = { entityType: entity.entityType, entityId: entity._id };
    const regoRes = await http.get(`/nrl/api/v1/portal/registrations/${person.id}`);
    const memberRegos =
      regoRes && regoRes.data && regoRes.data.data && regoRes.data.data.length
        ? regoRes.data.data
        : [];
    const d = new Date();
    let year = d.getFullYear();
    const season = regoRes && regoRes.data && regoRes.data.season ? regoRes.data.season : year;
    const regoExists =
      entity && entity.entityType && entity.entityType === "program"
        ? memberRegos.find(
            (r) =>
              r.entityType === check.entityType &&
              r.entityId === check.entityId &&
              r.season === season
          )
        : undefined;
    return regoExists;
  }
}
